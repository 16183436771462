<template>
  <v-card class="elevation-1 card-empty box-card visitor-card">
    <v-app-bar class="elevation-0 bg-whitesmoke app-bar">
      <v-toolbar-title class="primary--text ms-2 text-uppercase subtitle-1 font-weight-medium">
        <v-icon color="primary" left>mdi-needle</v-icon>
        Vaccine Info
      </v-toolbar-title>
    </v-app-bar>
    <div class="inputs-wrapper py-0 px-4 pt-6">
      <v-select
        clearable
        prepend-inner-icon="mdi-map-marker"
        v-model="selectedLocation"
        :loading="loadingLocations"
        @change="setLocation($event)"
        :disabled="loadingLocations"
        :items="vaccineLocations"
        item-value="id"
        item-text="name"
        label="Location"
        class=""
        dense
        outlined
        background-color="#fff"
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="data.item.address"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-select>
      <div class="d-flex">
        <v-select
          clearable
          prepend-inner-icon="mdi-doctor"
          v-model="nurse"
          :items="$store.state.vaccinePage.savedNurses"
          label="Nurse name and last name"
          dense
          outlined
          background-color="#fff"
        >
        </v-select>
        <v-btn fab small elevation="0" color="primary" class="ms-2" @click="showNurseDialog = true"><v-icon>mdi-plus</v-icon></v-btn>
        <CreateNewNurseModal v-if="showNurseDialog" :showNurseDialog="showNurseDialog" />
      </div>
    </div>
    <v-card-text class="text-area user-card vaccine-user-card">
      <transition name="wizard-down">
        <div v-if="vaccineInventory && vaccineInventory.length > 0" style="width: 100%">
          <!-- INVENTORY CARD -->
          <div v-for="box in vaccineInventory" :key="box.id">
            <InventoryCard :box="box" :selectedBox="selectedBox" @setSelectedBox="setSelectedBox" />
          </div>
        </div>
        <div v-else-if="vaccineInventory && vaccineInventory.length == 0" class="full-width text-center subtitle-1 align-self-center">
          No vaccines boxes at this location
        </div>
      </transition>
      <transition name="wizard-down">
        <div class="no-inventory-selected flex-column d-flex full-width justify-center" v-if="!vaccineInventory">
          <div class="check-icon-wrapper icon-wrapper">
            <v-icon>mdi-package-variant-closed</v-icon>
          </div>
          <div class="text text-center grey--text text--lighten-1 subtitle-1 mt-2">Please select a location above to view inventory</div>
        </div>
      </transition>
    </v-card-text>
  </v-card>
</template>

<script>
import InventoryCard from '@/views/VaccineViews/VaccineCheckoutPage/components/InventoryCard.vue';
import CreateNewNurseModal from '@/views/VaccineViews/VaccineCheckoutPage/components/CreateNewNurseModal.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';

export default {
  mixins: [SharedFunctions],
  components: {
    InventoryCard,
    CreateNewNurseModal
  },
  computed: {
    vaccineLocations() {
      return this.$store.state.vaccine.locations.filter((location) => location.dossageAllow);
    },
    vaccineInventory() {
      return this.$store.state.vaccine.inventoryAtLocation;
    },
    selectedLocation() {
      return parseInt(this.$store.state.vaccinePage.selectedLocation);
    },
    nurse: {
      get() {
        return this.$store.state.vaccinePage.selectedNurse;
      },
      set(value) {
        this.setLocalStorageObject('dvg_vaccine_data', 'nurse', value);
        this.$store.commit('SET_VACCINE_SELECTED_NURSE', value);
      }
    }
  },
  watch: {
    selectedBox(n) {
      this.$parent.selectedBox = n;
    }
  },
  data() {
    return {
      loadingLocations: false,
      selectedBox: { id: null },
      showNurseDialog: null
    };
  },
  created() {
    if (this.vaccineLocations.length == 0) this.getVaccineLocations();
    else this.checkLocalStorage();
  },
  methods: {
    getVaccineLocations() {
      this.loadingLocations = true;
      this.$store.dispatch('getVaccineLocations').finally(() => {
        this.loadingLocations = false;
        this.checkLocalStorage();
      });
    },
    setLocation(location) {
      this.setLocalStorageObject('dvg_vaccine_data', 'location', location);
      this.$store.commit('SET_VACCINE_SELECTED_LOCATION', location);

      this.selectedBox = { id: null };
      this.$store.commit('SET_VACCINE_INVENTORY_LOCATION', []);
      if (!location) return;
      this.loadingLocations = true;

      this.$store
        .dispatch('getVaccineInventoryByLocation', location)
        .then((data) => {
          if (data.length > 0 && data[0].location.dossageAllow && data.length == 1) this.selectedBox = data[0];
        })
        .finally(() => (this.loadingLocations = false));
    },
    setSelectedBox(box) {
      this.selectedBox = box;
    },
    checkLocalStorage() {
      const location = this.getLocalStorageObject('dvg_vaccine_data', 'location');
      if (location) this.setLocation(location);
      this.nurse = this.getLocalStorageObject('dvg_vaccine_data', 'nurse');
      let savedNurses = this.getLocalStorageObject('dvg_vaccine_data', 'saved_nurses');
      this.$store.commit('SET_VACCINE_SAVED_NURSES', savedNurses);
    }
  }
};
</script>


<style lang="scss">
.box-card {
  .inputs-wrapper {
    background: #f5f5f5;
    border-bottom: 1px solid darken(#f5f5f5, 10%);
    //display: flex;
    .v-input {
      flex: 1 1 0px;
      .v-input__slot {
        //box-shadow: none;
        //border: 1px solid lighten(grey, 40%);
      }
    }
  }
}
</style>