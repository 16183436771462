<template>
  <div>
    <!-- VACCINE CARD -->
    <v-card class="elevation-1 card-empty visitor-card">
      <v-app-bar class="elevation-0 bg-whitesmoke">
        <v-icon color="primary">mdi-account-multiple</v-icon>
        <v-toolbar-title class="primary--text ms-2 text-uppercase subtitle-1 font-weight-medium">Users</v-toolbar-title>
      </v-app-bar>

      <v-card-text class="bg-white text-area user-card vaccine-user-card">
        <transition name="wizard-down">
          <div v-if="users.length > 0" style="width: 100%">
            <!-- USER CARD -->
            <v-card
              v-for="user in users"
              :key="user.patientCaseId"
              @click="$parent.selectUserCard(user)"
              :ripple="false"
              :class="userSelected.patientCaseId == user.patientCaseId ? 'active-card' : ''"
              class="mb-4 relation-card pb-2 pt-0 elevation-0"
              outlined
            >
              <v-card-text class="pt-3">
                <div class="overline color" style="position: absolute; bottom: 5px; right: 8px">
                  <span class="">
                    {{ userSelected.patientCaseId == user.patientCaseId ? 'Selected' : '' }}
                  </span>
                </div>

                <!-- USER INFO -->
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title class="pt-0 px-0" v-text="user.firstname + ' ' + user.lastname"></v-card-title>
                    <v-card-subtitle class="px-0 py-0 case-number">{{ user.name }}</v-card-subtitle>
                    <div>
                      <span class="label">Birthday: </span>
                      <span class="value">{{ user.birthDay ? formatDate(user.birthDay) : 'Not filled' }}</span>
                    </div>
                    <div>
                      <span class="label">Email: </span>
                      <span class="value">{{ user.email ? user.email : 'Not filled' }}</span>
                    </div>
                  </div>

                  <v-avatar class="ma-2 check-icon-wrapper" size="60" rounded>
                    <v-icon>mdi-account</v-icon>
                  </v-avatar>
                </div>

                <!-- WARNING AND ERRORS -->
                <div class="my-2">
                  <v-alert color="warning" v-if="!user.vaccine" dense text class="body-2 mt-2 mb-2 white--text">
                    <v-icon left color="warning"> mdi-alert-circle</v-icon> User has no appointment for a vaccine
                    <div class="d-flex">
                      <v-spacer />
                      <transition name="slide-bottom">
                        <v-btn
                          v-if="userSelectedProp && userSelectedProp.patientCaseId == user.patientCaseId"
                          small
                          elevation="0"
                          class="mt-2 mb-2"
                          color="warning"
                          @click="openCreateAppointmentModal()"
                        >
                          <v-icon left>mdi-calendar-clock</v-icon> Create appointment
                        </v-btn>
                      </transition>
                    </div>
                  </v-alert>
                </div>

                <!-- CHECKINS -->
                <CheckinDataCard
                  v-if="user.vaccine && userSelected.patientCaseId == user.patientCaseId"
                  :users="users"
                  :user="user"
                  parent="vaccineScan"
                  @showCheckinConfirmModal="showCheckinConfirmModal"
                />

                <!-- VACCINE INFO -->
                <transition name="slide-bottom">
                  <template v-if="user.vaccine && userSelected.patientCaseId == user.patientCaseId">
                    <div class="vaccine-info mt-3">
                      <div>
                        <DosageCard v-for="vaccine in vaccineData" :key="vaccine.vaccineGroupedName"
                          :dosage="vaccine.vaccineDose"
                          :appointment="vaccine.appointment"
                          :vaccineNumber="vaccine.vaccineNr"
                          :vaccineDate="vaccine.vaccineDate"
                        />
                      </div>
                    </div>
                  </template>
                </transition>
              </v-card-text>
            </v-card>
          </div>
        </transition>
        <transition name="wizard-down">
          <div v-if="users.length == 0" class="check-icon-wrapper icon-wrapper">
            <v-icon>mdi-account-multiple</v-icon>
          </div>
        </transition>
      </v-card-text>
    </v-card>

    <!-- DIALOG VACCINE CALENDAR -->
    <VaccineCalendarDialog
      :dialogProp="vaccineCalendarDialog"
      v-if="vaccineCalendarDialog"
      :caseSelectedData="{ id: userSelectedProp.patientCaseId }"
      :prefillNow="true"
      @vaccineCalendarDialogCallback="vaccineCalendarDialogDone"
    />
  </div>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import DosageCard from '@/views/VaccineViews/components/DosageCard.vue';
import CheckinDataCard from '@/views/VaccineViews/components/CheckinDataCard.vue';
import VaccineCalendarDialog from '@/views/VaccineViews/components/vaccine-calendar-dialog.vue';

export default {
  mixins: [SharedFunctions],
  props: ['users', 'loadProgress', 'userSelectedProp', 'userSelectedCheckInData'],
  components: {
    DosageCard,
    CheckinDataCard,
    VaccineCalendarDialog,
  },
  data() {
    return {
      //sharedFunctions: SharedFunctions,
      vaccineCalendarDialog: false,
      vaccineData: {}
    };
  },
  watch: {
    userSelectedProp(user)
    {
      this.vaccineData = this.groupVaccineData(user.vaccine);
    }
  },
  computed: {
    userSelected() {
      if (this.userSelectedProp) return this.userSelectedProp;
      else {
        return {
          patientCaseId: null
        };
      }
    }
  },
  methods: {
    showCheckinConfirmModal() {
      this.$parent.showCheckinModal();
    },
    openCreateAppointmentModal() {
      console.log('openCreateAppointmentModal');
      this.vaccineCalendarDialog = true;
    },
    async vaccineCalendarDialogDone(data) {
      const userData = await this.$store.dispatch('getPatientCase', this.userSelectedProp.patientCaseId);
      const vaccineData = await this.$store.dispatch('getVaccinePatientCase', this.userSelectedProp.patientCaseId);
      this.$parent.updateCardData(userData, vaccineData, this.userSelectedProp.patientCaseId);
      this.vaccineCalendarDialog = false;
      data.done();
    },
    updateDataUsers(users, subjectData) {
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        //check for both, different naming different in roles
        if (user.patientCaseId == subjectData.id || user.patientCaseId == subjectData.patientCaseId) {
          users[i] = subjectData;
          break;
        }
      }
      return users;
    }
  }
};
</script>

<style lang="scss" >
.visitor-card {
  .v-card--link:focus:before {
    opacity: 0 !important;
  }
  .overline.color {
    color: #2196f3;
    font-weight: 600;
  }
  .vaccine-user-card {
    .label {
      color: darken(grey, 5%);
    }
    .value {
      font-weight: 500;
      color: darken(grey, 30%);
    }
    .check-icon-wrapper {
      background: whitesmoke;
      i {
        font-size: 2.5rem;
        color: lightgrey;
      }
    }
    .relation-card {
      border-radius: 10px !important;
      .title {
        font-size: 1.1rem !important;
        margin-bottom: 2px;
      }
      .case-number {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 4px;
      }
      &:hover {
        //background: rgba(#2196f3, .1) !important;
        box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.2) !important;
      }
    }
    .overline {
      font-size: 14px !important;
    }
    .active-card {
      border: 3px solid #2196f3 !important;
    }
    .vaccine-info {
      .v-icon {
        color: lighten(grey, 20%);
        margin-right: 5px;
      }
    }
  }

  .loader-bar {
    height: 3px;
    width: 100%;
    .fill {
      width: 0%;
      transition: none;
    }
    .in {
      transition-property: width;
      transition-timing-function: linear;
      height: 100%;
      background-color: lighten(#366baf, 35%);
      width: 100%;
    }
  }
}
</style>
