<template>
  <div class="">
    <v-dialog v-model="showNurseDialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2"> Enter new nurse </v-card-title>

        <v-card-text>
          <v-text-field
            ref="nurseNameInput"
            v-model="nurse"
            dense
            label="Nurse name & last name"
            prepend-inner-icon="mdi-doctor"
            outlined
            class="mt-8"
            background-color="#fff"
          ></v-text-field>
          <h3 class="mb-2">Saved Nurses</h3>
          <v-divider />
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(nurseItem, i) in savedNurses" :key="nurseItem + '-' + i">
                  <td>{{ nurseItem }}</td>
                  <td>
                    <v-btn fab x-small elevation="0" @click="removeNurse(i)"><v-icon>mdi-minus</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeModal()"> Cancel </v-btn>
          <v-btn color="primary" @click="saveNewNurse()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
export default {
  mixins: [SharedFunctions],
  props: ['showNurseDialog'],
  data() {
    return {
      nurse: null,
      savedNurses: [],
    };
  },
  created() {
    this.savedNurses = this.getLocalStorageObject('dvg_vaccine_data', 'saved_nurses');
    setTimeout(() => this.$refs.nurseNameInput.focus(), 300);
  },
  methods: {
    saveNewNurse() {
      if (this.nurse) {
        if (this.savedNurses && this.savedNurses.length > 0) this.savedNurses.push(this.nurse);
        else this.savedNurses = [this.nurse];
      }
      this.setLocalStorageObject('dvg_vaccine_data', 'saved_nurses', this.savedNurses);
      this.$store.commit('SET_VACCINE_SAVED_NURSES', this.savedNurses);
      this.nurse = null;
      this.closeModal();
    },
    closeModal() {
      this.$parent.$parent.showNurseDialog = false;
    },
    removeNurse(index) {
      this.savedNurses.pop(index);
    },
  },
};
</script>

<style>
</style>