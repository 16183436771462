<template>
  <v-container class="tourist-scan-page" v-if="hasAccessToPage">
    <v-row justify="center">
      <v-col cols="12" md="12" lg="12" xl="10">
        <VaccineScanInput :disableScanInput="disableScanInput" :caseId="caseId" />
      </v-col>
    </v-row>
    <v-row class="mt-0" justify="center">
      <v-col cols="12" sm="12" md="6" lg="6" xl="5">
        <VaccineCheckoutCards :users="users" :loadProgress="loadProgress" :userSelectedProp="userSelected" />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="5">
        <VaccineInfo />
      </v-col>
    </v-row>
    <CheckoutConfirmModal v-if="showConfirmModal" :user="userSelected" :box="selectedBox" :showConfirmModal="showConfirmModal" />
  </v-container>
  <NoAccessMessage v-else />
</template>

<script>
import VaccineCheckoutCards from '@/views/VaccineViews/VaccineCheckoutPage/components/VaccineCheckoutCards.vue';
import VaccineScanInput from '@/views/VaccineViews/components/VaccineScanInput.vue';
import VaccineInfo from '@/views/VaccineViews/VaccineCheckoutPage/components/VaccineInfo.vue';
import CheckoutConfirmModal from '@/views/VaccineViews/VaccineCheckoutPage/components/CheckoutConfirmModal.vue';
import '@/assets/scss/visitor-scan-generic.scss';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import NoAccessMessage from '@/components/generic/no-access-message.vue';
//import moment from 'moment';

export default {
  mixins: [SharedFunctions],
  components: {
    VaccineCheckoutCards,
    VaccineScanInput,
    VaccineInfo,
    NoAccessMessage,
    CheckoutConfirmModal
  },
  data() {
    return {
      userSelected: null,
      userSelectedCheckInData: [],
      users: [],
      loadProgress: null,
      scannedCaseId: null,
      disableScanInput: false,
      hasAccessToPage: false,
      intervalTimer: null,
      showConfirmModal: false,
      selectedBox: null,
      caseId: this.$route.params.caseId ? this.$route.params.caseId : null
    };
  },
  computed: {
    dataDisplayed() {
      return this.userSelected ? true : false;
    },
    selectedNurse() {
      return this.$store.state.vaccinePage.selectedNurse;
    },
    saveButtonDisabled() {
      return this.selectedBox && this.selectedBox.id && this.userSelected && this.selectedNurse ? false : true;
    },
    locationSelectedMesg() {
      if (this.$store.state.vaccinePage.selectedLocation) return null;
      else return 'Please select a location';
    },
    boxSelectedMesg() {
      if (this.selectedBox && this.selectedBox.id) return null;
      else return 'Please select a vaccine box';
    },
    userSelectedMesg() {
      if (this.userSelected) return null;
      else return 'Please select a user';
    },
    nurseSelectedMesg() {
      if (this.selectedNurse) return null;
      else return 'Please fill in a nurse name';
    },
    locations() {
      return this.$store.state.vaccine.locations;
    },
    selectedLocation() {
      return parseInt(this.$store.state.vaccinePage.selectedLocation);
    }
  },
  created() {
    this.hasAccessToPage = this.hasAccessToPageMixin();
  },
  mounted() {
    //this.$store.commit('SET_BACK_BUTTON', false);
    this.focusScanInput();
  },
  beforeDestroy() {
    clearTimeout(this.intervalTimer);
    this.resetAllData();
  },
  methods: {
    displaySubjectData(subjectData, scannedCaseId) {
      //if scanned input passed, find it and check if it has vaccine, if so select it
      if (scannedCaseId) this.userSelected = subjectData.filter((subject) => subject.patientCaseId == scannedCaseId)[0];
      else this.userSelected = null;
      this.users = subjectData;
      this.scannedCaseId = scannedCaseId;
      this.focusScanInput();
      this.getCheckInDataUsers(subjectData);
      //this.initIntervalScan(true);
    },
    updateCardData(userData, vaccineData, patientCaseId) {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].patientCaseId == patientCaseId) {
          this.$set(this.users, i, userData);
          this.$set(this.users[i], 'vaccine', vaccineData);
          this.userSelected = userData;
          this.userSelected.vaccine = vaccineData;
          this.getCheckInDataUser(this.users[i], i);
          break;
        }
      }
    },
    selectUserCard(user) {
      this.userSelected = user;
      this.scannedCaseId = user.patientCaseId;
      this.testTubeMessage = null;
    },
    async getCheckInDataUsers(users) {
      let i = 0;
      for await (const user of users) {
        const data = await this.$store.dispatch('getCheckInDataUser', user.patientCaseId);
        this.$set(this.users[i], 'checkInData', data);
        i++;
      }
    },
    async getCheckInDataUser(user, index) {
      const data = await this.$store.dispatch('getCheckInDataUser', user.patientCaseId);
      this.$set(this.users[index], 'checkInData', data);
    },
    focusScanInput() {
      setTimeout(() => document.getElementById('scanTextInput').focus(), 0);
    },
    resetAllData() {
      this.users = [];
      this.userSelected = null;
      this.testTubeKitInfo = null;
      this.disableScanInput = false;
      this.scannedCaseId = null;
    },
    resetDataAfterLinkCreated() {
      this.disableScanInput = false;
      this.focusScanInput();
    },
    showCheckinModal() {
      this.selectedLocationDisplay = this.locations[this.selectedLocation - 1];
      this.selectedBox = false;
      this.confirmModalTitle = 'Checkin user to location';
      this.showConfirmModal = true;
    },
    setCheckinData(data) {
      this.$set(this.users[data.index], 'checkInData', data.checkinData);
    }
  }
};
</script>
