<template>
  <v-dialog v-model="showConfirmModal" width="900" scrollable persistent>
    <v-card class="received-form" v-if="user">
      <v-card-title class="headline grey lighten-3 mb-4 grey--text text--darken-1">
        Save vaccine dosage to user
      </v-card-title>
      <v-card-text class="d-flex pt-2 pb-8 justify-space-between align-center" style="align-items: start">
        <!-- VACCINE INFO -->
        <v-card class="pb-4 info-card align-self-start">
          <v-app-bar class="py-0 mb-2 mt-0 primary white--text title" elevation="0" dense color="primary">
            <v-icon left color="#fff">mdi-package-variant-closed </v-icon> Vaccine Info</v-app-bar
          >
          <div class="text">
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Batch number</v-list-item-subtitle>
                <v-list-item-title class="caption">{{ box.boxNumber }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4 my-1" />
            <div class="d-flex">
              <v-list-item>
                <v-list-item-content class="my-0 py-0">
                  <v-list-item-subtitle>Amount of vials</v-list-item-subtitle>
                  <v-list-item-title>{{ box.amountOfVials }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="my-0 py-0">
                  <v-list-item-subtitle>Doses left</v-list-item-subtitle>
                  <v-list-item-title>{{ box.currentDosageLevel }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
            <v-divider class="mx-4 my-1" />
            <div class="d-flex">
              <v-list-item>
                <v-list-item-content class="my-0 py-0">
                  <v-list-item-subtitle>Transfer date</v-list-item-subtitle>
                  <v-list-item-title>{{ formatDate(box.creation) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="box.dosisExpiration">
                <v-list-item-content class="my-0 py-0">
                  <v-list-item-subtitle>Expire date</v-list-item-subtitle>
                  <v-list-item-title>{{ formatDate(box.dosisExpiration) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
            <v-divider class="mx-4 my-1" />
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Location</v-list-item-subtitle>
                <v-list-item-title>{{ box.location.name }}, {{ box.location.address }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4 my-1" />
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Nurse</v-list-item-subtitle>
                <v-list-item-title>{{ $store.state.vaccinePage.selectedNurse }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-card>
        <!-- ARROW -->
        <v-avatar class="mx-4" color="primary">
          <v-icon color="white" large>mdi-arrow-right-thick</v-icon>
        </v-avatar>
        <!-- USER -->
        <v-card class="pb-4 info-card align-self-start">
          <v-app-bar class="py-0 mb-2 mt-0 primary white--text title" elevation="0" dense color="primary"
            ><v-icon left color="white">mdi-account </v-icon> User Info</v-app-bar
          >
          <div class="text">
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Name & last name</v-list-item-subtitle>
                <v-list-item-title>{{ user.firstname }} {{ user.lastname }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4 my-1" />
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Case</v-list-item-subtitle>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4 my-1" />
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Birthday</v-list-item-subtitle>
                <v-list-item-title>{{ formatDate(user.birthDay) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-card>
      </v-card-text>

      <v-divider></v-divider>

      <v-alert type="error" text v-if="notAllowedToSubmit" class="ma-4">
        Cannot submit because {{ this.minDaysBetweenVaccines }} days have not passed since last vaccine.
      </v-alert>

      <v-alert text type="error" v-if="hasEightShots" class="ma-4">Has 8 shots already</v-alert>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()" :disabled="submiting">Cancel</v-btn>
        <v-btn
          color="success"
          large
          @click="submitScan()"
          :disabled="submiting || notAllowedToSubmit || hasEightShots"
          :loading="submiting"
          class="elevation-0"
          ref="submitBtn"
        >
          Submit
          <v-icon right>mdi-send</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
//import moment from 'moment';
export default {
  mixins: [SharedFunctions],
  props: ['user', 'box', 'showConfirmModal'],
  computed: {
    selectedNurse() {
      return this.$store.state.vaccinePage.selectedNurse;
    },
    isBoosterShot() {
      if (this.user?.vaccine?.vaccine1 && this.user?.vaccine?.vaccine2 && !this.user?.vaccine?.vaccine3) return true;
      else return false;
    },
    hasEightShots() {
      if (this.user?.vaccine?.vaccine1 && this.user?.vaccine?.vaccine2 && this.user?.vaccine?.vaccine3 && this.user?.vaccine?.vaccine4 &&
      this.user?.vaccine?.vaccine5 && this.user?.vaccine?.vaccine6 && this.user?.vaccine?.vaccine7 && this.user?.vaccine?.vaccine8) return true;
      else return false;
    }
  },
  data() {
    return {
      submiting: false,
      minDaysBetweenVaccines: 7,
      notAllowedToSubmit: false
    };
  },
  async created() {
    this.checkVaccineTimeBetween();
  },
  methods: {
    closeModal() {
      this.$parent.showConfirmModal = false;
    },
    async submitScan() {
      if (!this.alreadyCheckedInToday()) {
        this.$store.commit('SET_SNACKBAR', { open: true, text: 'User has did not check in today, please check user in first', color: 'warning' });
        return;
      }
      const payload = {
        inventoryId: this.box.id,
        bodyToSubmit: {
          patientCase: {
            id: this.user.patientCaseId
          },
          nurse: this.selectedNurse
        }
      };

      try {
        this.submiting = true;
        //dose link
        const doseData = await this.$store.dispatch('doseUser', payload);
        if (!doseData) {
          return;
        }
        //get user and vaccine data
        const userData = await this.$store.dispatch('getPatientCase', this.user.patientCaseId);
        const vaccineData = await this.$store.dispatch('getVaccinePatientCase', this.user.patientCaseId);
        //get inventory data
        await this.$store.dispatch('getVaccineInventoryByLocation', this.$store.state.vaccinePage.selectedLocation);
        this.$parent.updateCardData(userData, vaccineData, this.user.patientCaseId);
        this.closeModal();

        let payloadMesg = {
          open: true,
          text: 'Vaccine successfully linked to user',
          color: 'success'
        };
        this.$store.commit('SET_SNACKBAR', payloadMesg);
      } catch (err) {
        let payload = {
          open: true,
          text: 'Cannot reload data, please refresh page',
          color: 'error'
        };
        this.$store.commit('SET_SNACKBAR', payload);
      } finally {
        this.submiting = false;
        //this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
      }
    },
    checkVaccineTimeBetween() {
      //if first vaccine given and second not, check if enough time passed
      let daysBetween = null;
      
      if (!this.user.vaccine.vaccine1) {
        this.notAllowedToSubmit = false;
        return;
      }

      if (this.user.vaccine.vaccine1 && !this.user.vaccine.vaccine2) {
        daysBetween = this.daysAfterTillToday(this.user.vaccine.vaccine1);
      }
      if (this.user.vaccine.vaccine2 && !this.user.vaccine.vaccine3) {
        daysBetween = this.daysAfterTillToday(this.user.vaccine.vaccine2);
      }
      if (this.user.vaccine.vaccine3 && !this.user.vaccine.vaccine4) {
        daysBetween = this.daysAfterTillToday(this.user.vaccine.vaccine3);
      }
      if (this.user.vaccine.vaccine4 && !this.user.vaccine.vaccine5) {
        daysBetween = this.daysAfterTillToday(this.user.vaccine.vaccine4);
      }
      if (this.user.vaccine.vaccine5 && !this.user.vaccine.vaccine6) {
        daysBetween = this.daysAfterTillToday(this.user.vaccine.vaccine5);
      }
      if (this.user.vaccine.vaccine6 && !this.user.vaccine.vaccine7) {
        daysBetween = this.daysAfterTillToday(this.user.vaccine.vaccine6);
      }
      if (this.user.vaccine.vaccine7 && !this.user.vaccine.vaccine8) {
        daysBetween = this.daysAfterTillToday(this.user.vaccine.vaccine7);
      }

      if (daysBetween != null && daysBetween <= this.minDaysBetweenVaccines) 
        this.notAllowedToSubmit = true;
      else this.notAllowedToSubmit = false;
    },
    alreadyCheckedInToday() {
      if (this.user.checkInData) {
        let alreadyCheckedInToday = false;
        for (let i = 0; i < this.user.checkInData.length; i++) {
          const element = this.user.checkInData[i];
          if (this.isToday(element.insertDate)) {
            alreadyCheckedInToday = true;
            break;
          }
        }
        return alreadyCheckedInToday;
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.info-card {
  flex: 1 1 0;
  width: 0;
}
</style>
